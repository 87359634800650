footer {
    height: auto;
    padding-bottom: 2%;
    background-color: var(--fifth-color);
    background-image: url("../../../assets/static/icons/icon_mediasilla.svg");
    background-repeat: no-repeat;
    background-position: bottom left;
    color: var(--fourth-color);
    font-size: 24px;
    font-weight: 200;
}

.rendaraLogo {
    margin: 53px;
    margin-bottom: 53px;
}

.footerTitle {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 3%;
}

.footerInvTitle {
    visibility: hidden;
}

.line {
    position: relative;
    top: 30px;
    height: 125px;
    border-left: 2px solid var(--fourth-color);
}

footer .MuiButton-root {
    display: inline-block;
    width: 46px;
    height: 46px;
}

footer a:-webkit-any-link {
    color: var(--fourth-color) !important;
    font-size: 21px;
    text-decoration: none !important;
}

.facebookIcon {
    background: no-repeat url('../../../assets/static/icons/icon_facebook.svg') center;
}

.instagramIcon {
    background: no-repeat url('../../../assets/static/icons/icon_instagram.svg') center;
}

.youtubeIcon {
    background: no-repeat url('../../../assets/static/icons/icon_youtube.svg') center;
}

.footer-web-container {
    display: none;
}

.footer-mobile-container {
    width: 70%;
}

.footer-mobile-links  {
    margin: 20px 0px;
}

.footer-mobile-links-container {
    display: flex;
    flex-direction: column;
}

.ma-container {
    font-size: 21px;
    padding-top: 20px;
    padding-left: 16px;
    width: 75%;
}

@media all and (max-width: 900px) {
    footer {
        height: auto;
        background-image: none;
    }
    
    .rendaraLogo {
        margin: 30px 13% 50px;
    }

    .footerInvTitle {
        display: none;
    }

    .line {
        top: 0px;
        bottom: auto;
        height: auto;
        width: 75vw;
        border-left: 0;
        border-bottom: 2px solid var(--fourth-color);
    }

}

@media (min-width:900px) {
    .footer-mobile-container {
        display: none;
        
    }
    .footer-web-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex-wrap: wrap;
        align-content: center;
        margin: 0 30px;
    }
    .footer-web-container .footer-web-title {
        margin: 0 20px;
    }
    .footer-web {
        display: flex;
    }
    .footer-web-links-container {
        margin: 0px 20px
    }
    .footer-web-links  {
        margin: 20px 0px;
    }
}
