.DetailArea {
  width: 85%;
  margin: 30px auto;
}

@media (max-width: 899px) {
  .DetailArea {
    width: 90%;
    margin: 30px auto;
  }
}


.DetailArea .btn_detail, .DetailArea .btn_detail_w{
  background-color: #FF4800;
  border: 1px solid #FF4800;
  padding: 10px 15px;
  width: 100%;
  color: #FFFFFF;
  margin: 3px;
  border-radius: 4px;
  text-align: left;
  font-size: 18px;
  background-position: 97% 50%;
  background-repeat: no-repeat;
  background-size: auto 80%;
}

.DetailArea .pd_area_btn{
  margin-top: 40px;
}

.DetailArea .btn_detail_w{
  background-color: #FFFFFF;
  color: #FF4800;
}

.DetailArea .btn_ok{
  background-image: url(../static/icons/icon_arrendar_ok.svg);
}

.DetailArea .btn_cita{
  background-image: url(../static/icons/icon_cita.svg);
}

.DetailArea .btn_whatsapp{
  background-image: url(../static/icons/icon_whatsapp2.svg);
}