.faqContainer {
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
}

.faqContainer .faqTitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 24.55px;
  color: var(--fifth-color);
  margin: 45px 0 15px 15px;
}

.contactButtonContainer {
  display: flex;
  justify-content: center;
}

.fq-Button {
  background-color: #FF5614;
  width: 304px;
  height: 51px;
  align-self: center;
  border-radius: 25px;
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-top: 60px;
  margin-bottom: 80px;
  color: white;
}

@media (min-width: 576px) {
  .faqContainer .faqTitle {
    font-size: 24px;
    margin: 45px 0 15px 25px;
  }
}

@media (min-width: 900px) {
  .faqContainer .faqTitle {
    font-size: 38px;
    margin: 84px 0 15px 55px;
  }
  .fq-Button {
    height: 50px;
    width: 280px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }
  .fq-Button {
    width: 413px;
    height: 53px;
    font-size: 24px;
    margin-top: 120px;
    margin-bottom: 120px;
  }
}
