.postYourPropertyExperience {
    width: 97%;
    margin-inline: auto;
}

.quote {
    border: 1px dashed var(--first-color);
    border-radius: 20px;
    padding: 4%;
    font-size: 31px;
    font-weight: 500;
    text-align: center;
    color: var(--second-color);
}

.title1 {
    width: 85%;
    margin: 6% auto 5% auto;
    font-size: 32px;
    font-weight: 700;
    color: var(--second-color);
}

.ourExperience {
    width: 85%;
    margin: 2% auto 8% auto;
    display: flex;
    align-items: center;
}

.number1 {
    font-size: 100px;
    font-weight: 900;
    color: var(--first-color);
}

.description1 {
    margin-left: 7%;
    font-size: 22px;
    font-weight: 450;
    color: var(--second-color);
}

.firstPart {
    font-size: 45px;
    font-weight: 450;
    text-align: center;
    color: var(--second-color);
}

.secondPart {
    margin-bottom: 10%;
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    color: var(--second-color);
}

@media all and (max-width: 600px) {
    .postYourPropertyExperience {
        width: auto;
        margin-inline: none;
    }
    
    .quote {
        border: none;
        padding: 4%;
        font-size: 5vw;
        font-weight: 600;
    }
    
    .title1 {
        font-size: 6vw;
        text-align: center;
    }
    
    .ourExperience {
        flex-direction: column;
    }
    
    .number1 {
        font-size: 20vw;
    }
    
    .description1 {
        margin-left: 0;
        font-size: 4vw;
    }
    
    .firstPart {
        margin-inline: 5%;
        font-size: 8vw;
    }
    
    .secondPart {
        margin-inline: 5%;
        font-size: 4vw;
    }        
}