.container_client_thinking {
    width: 97%;
    margin-bottom: 15%;
    margin-inline: auto;
    padding-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.container_client_thinking .title_client_thinking {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container_client_thinking .title_client_thinking p {
    text-align: center;
    font-size: 18px;
    font-weight: 800;
    line-height: 24.55px;
    color: var(--sixth-color);
}

.container_client_thinking .container_card_client_thinking {
    display: flex;
}

.mySwiper {
    width: 320px;
    display: flex;
    justify-content: center;
}

.swiper-pagination-bullet:root {
    --swiper-theme-color: #3D5A80;
}

.container_client_thinking .card_client_thinking {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.container_client_thinking .card_client_thinking .card_client_thinking_name {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 32.74px;
    color: var(--sixth-color);
    margin-top: 50px;
}

.card_client_thinking_stars img {
    margin: 0 5px;
}

.card_client_thinking_text {
    margin: 17px 0 40px 0;
    font-size: 18px;
    font-weight: 300;
    Line-height: 24.55px;
    color: var(--second-color);
    width: 320px;
}

.card_client_thinking_text b {
    font-weight: 700;
}

.container_card_client_thinking_web {
    display: none;
}

.button_client_thinking {
    background-color: var(--first-color);
    width: 90%;
    height: 37px;
    align-self: center;
    border-radius: 25px;
    border: none;
}

.button_client_thinking .button_client_thinking_text {
    font-weight: 500;
    font-size: 21px;
    line-height: 28px;
    margin: 35px 0 10px 0;
    color: var(--fourth-color) !important;
    
}

.swiper-pagination-bullet {
    --swiper-theme-color: var(--first-color);
}

@media (min-width:576px) {
    .container_client_thinking {
        margin-bottom: 100px;
    }

    .container_client_thinking .title_client_thinking p {
        font-size: 24px;
        line-height: 0px;
    }
    .button_client_thinking .button_client_thinking_text {
        
        font-size: 24px;
        line-height: 32.74px;
    }
    .button_client_thinking {
        width: 390px;
        height: 45px;
    }
}

@media (min-width:992px) {
    .container_client_thinking {
        margin-bottom: 5%;
        background-repeat: no-repeat;
        background-position: left 0px bottom 110px;
    }

    .mySwiper {
        display: none;
    }

    .title_client_thinking {
        width: 85%;
    }

    .container_client_thinking .title_client_thinking p {
        width: 100%;
        margin-bottom: 30px;
        font-size: 32px;
        text-align: left;
    }

    .container_card_client_thinking_web {
        display: flex;
        width: 100%;
    }
    .button_client_thinking .button_client_thinking_text {
        font-size: 24px;
        line-height: 32.74px;
    }
    .button_client_thinking {
        width: 413px;
        height: 53px;
    }

}

@media (min-width:1200px) {
    .container_client_thinking .title_client_thinking p {
        margin-top: 80px;
    }
}

