.searcher {
  width: 100%;
  overflow: hidden;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.searcherBannerImageWeb {
  width: 100%;
  max-height: 100%;
  display: block;
}

.searcherBannerImageMobile,
.searcherTitleMobile {
  display: none;
}

.searcherTitleWeb {
  position: absolute;
  top: 24%;
  font-size: 45px;
  color: var(--fourth-color);
  font-weight: 600;
}

.searcherSearcher {
  width: 84%;
  height: 68px;
  position: absolute;
  top: 44%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 34px;
  background-color: white;
}

.homeSearcherUbicationSec {
  padding: 0px 0px 0px 40px;
  flex-basis: 0;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.homeSearcherSelectorSec {
  padding: 0px 25px 0px 40px;
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.searcherSearcher .MuiAutocomplete-input {
  padding: 0 !important;
  font-family: "Nunito";
  font-size: 16px;
  color: #707070;
}

.searcherSearcher .MuiInputBase-root {
  height: 34px;
  padding: 0 !important;
}

.searcherSearcher .MuiSelect-select {
  padding-left: 0px !important;
  font-family: "Nunito";
  font-size: 16px;
  color: #707070;
}

.searcherSearcher .MuiOutlinedInput-notchedOutline {
  padding-right: 0px;
  border: none;
}

.searcherSearcher label {
  font-size: 18px;
}

.homeSearcherSep {
  height: 63%;
  margin-top: auto;
  margin-bottom: auto;
  border-left: 2px solid #707070;
}

.searcherButton {
  width: 149px;
  height: 55px;
  position: absolute;
  top: 62%;
  background-color: #ff5614;
  border: none;
  border-radius: 27px;
}

.searcherButton a {
  font-size: 28px;
  font-weight: 500;
  color: var(--fourth-color) !important;
}

@media all and (max-width: 1200px) {
  .searcherSearcher label {
    font-size: 13px;
  }

  .homeSearcherSelectorSec {
    padding: 0px 15px 0px 15px;
  }

  .homeSearcherUbicationSec {
    padding: 0px 10px 0px 30px;
  }

}

@media all and (max-width: 980px) {
  .searcherBannerImageWeb,
  .searcherTitleWeb,
  .homeSearcherSep {
    display: none;
  }

  .searcherBannerImageMobile {
    display: inline;
    width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  .searcherTitleMobile {
    display: inline;
    position: absolute;
    top: 11%;
    font-size: 24px;
    font-weight: 600;
    color: var(--fourth-color);
    text-align: center;
  }

  .searcherSearcher {
    width: 85%;
    top: 32%;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 24px;
    background-color: transparent;
  }

  .homeSearcherUbicationSec,
  .homeSearcherSelectorSec {
    min-height: 55px;
    padding: 0px;
    border-radius: 27px;
    background-color: white;
  }

  .searcherSearcher label {
    padding-left: 22px;
    font-size: 16px;
  }

  .searcherSearcher .MuiAutocomplete-input {
    padding: 0px 0px 0px 33px !important;
    font-size: 14px;
  }

  .searcherSearcher .MuiSelect-select {
    padding: 0px 0px 0px 33px !important;
    font-size: 14px;
  }

  .searcherSearcher .MuiInputBase-root {
    height: 27.5px;
    padding: 0 !important;
  }

  .searcherButton {
    top: 88%;
    min-width: 80%;
    height: 51px;
    border-radius: 25px;
  }

  .searcherButton a {
    font-size: 18px;
  }
}
