.ImageBannerMobileContainer {
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ImageBannerMobile {
    display: block;
    width: 100%;
    max-height: 100%;
}
.ImageBannerMobileContainer .title_banner_mobile {
    color: var(--fourth-color);
    width: 100%;
    height: 50%;
    font-size: 28px;
    font-weight: 700;
    line-height: 38.19px;
    position: absolute;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.ImageBannerMobileContainer .text_banner_mobile {
    color: var(--sixth-color);
    width: 90%;
    font-size: 18px;
    font-weight: 700;
    line-height: 24.55px;
    display: block;
    text-align: center;
    margin-top: 27px;
}
.ImageBannerWeb {
    display: none;
}

.title_banner_web {
    display: none;
}

.text_banner_web {
    display: none;
}

@media (min-width: 350px) {
    .ImageBannerMobileContainer .title_banner_mobile {
        height: 55%;
    }
}

@media (min-width: 400px) {
    .ImageBannerMobileContainer .title_banner_mobile {
        height: 60%;
    }
}

@media (min-width: 460px) {
    .ImageBannerMobileContainer .title_banner_mobile {
        height: 70%;
    }
}

@media (min-width: 550px) {
    .ImageBannerMobileContainer .title_banner_mobile {
        height: 75%;
    }
}


@media (min-width: 600px) {
    .ImageBannerMobileContainer .title_banner_mobile {
        font-size: 32px;
    }
}

@media (min-width: 728px) {
    .ImageBannerMobileContainer .title_banner_mobile {
        height: 80%;
    }
}

@media (min-width: 900px) {
    .ImageBannerMobileContainer {
        display: none;
    }
    .ImageBannerMobile {
        display: none;
    }
    .ImageBannerWebContainer {
        width: 100%;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ImageBannerWeb {
        display: block;
        width: 100%;
        max-height: 100%;
    }
    .ImageBannerWebContainer .title_banner_web {
        color: var(--fourth-color);
        width: 100%;
        height: 277px;
        font-size: 38px;
        font-weight: 700;
        line-height: 51.83px;
        position: absolute;
        display: block;
        top: 20%;
        text-align: center;
    }
    .ImageBannerWebContainer .text_banner_web {
        color: var(--fourth-color);
        width: 80%;
        height: 277px;
        font-size: 24px;
        font-weight: 700;
        line-height: 32.74px;
        position: absolute;
        display: block;
        top: 55%;
        text-align: center;
    }
}