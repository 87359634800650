:root {
  --first-color: #ff4800;
  --second-color: #050a0f;
  --third-color: #fd342a;
  --fourth-color: #ffffff;
  --fifth-color: #44464a;
  --sixth-color: #000000;
}

@font-face {
  font-family: "Nunito";
  src: url("../static/fonts/Nunito/Nunito-VariableFont_wght.ttf");
  font-display: swap;
  font-weight: 200 1000;
  font-stretch: 75% 125%;
}

* {
  font-family: "Nunito", sans-serif !important;
  margin: 0;
  padding: 0;
}

body::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #edf8f8;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--first-color);
  border-radius: 20px;
  border: 3px solid var(--first-color);
}

html,
body {
  height: 100%;
  position: relative;
}

.lower {
  text-transform: lowercase;
}
