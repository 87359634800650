.Result {
  width: 100%;
  height: calc(100vh - 100px);
  position: relative;
  overflow: hidden;
  padding: 0px;
}

@media (max-width: 899px) {
  .Result {
    overflow: unset;
  }
}
