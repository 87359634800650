.hubspotFormContainer {
    width: 90%;
    margin: 0 auto 50px auto;
}

@media (min-width: 550px) {
    .hubspotFormContainer {
        width: 80%;
        margin: 0px auto 60px auto;
    }
}

@media (min-width:900px) {
    .hubspotFormContainer {
        width: 80%;
        margin: 70px auto 70px auto;
    }
}
