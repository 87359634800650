.pYPBannerMobile {
    display: none;
}
.pYPBannerWeb {
    position: relative;
    height: auto;
    margin-bottom: 5%;
}

.pYPImgDiv {
    display: flex;
    flex-direction: row-reverse;
}

.pYPImg {
    display: inline;
    width: 60%;
    max-height: 100%;
}

.pYPBannerWeb a:-webkit-any-link {
    text-decoration: none !important;
}

.pYPTitle {
    position: absolute;
    top: 17%;
    left: 6%;
    width: 30%;
    font-size: min(50px, 4vw);
    font-weight: 700;
    color: var(--sixth-color);
}

.pYPButton {
    --bttn-height: 53px;
    position: absolute;
    top: 75%;
    left: 6%;
    height: var(--bttn-height);
    width: 30%;
    border: 0;
    border-radius: calc(var(--bttn-height) / 2);
    font-size: min(24px, 2vw);
    color: var(--fourth-color);
    background-color: var(--first-color);
}

@media all and (max-width: 900px) {
    .pYPBannerWeb {
        display: none;
    }

    .pYPBannerMobile {
        width: 100%;
        display: flex;
        margin-bottom: 67px;
        flex-direction: column;
        align-items: center;
    }

    .pYPMobile {
        display: static;
    }
    
    .pYPBannerWeb {
        width: 100%;
    }

    .pYPImgDiv {
        display: inline;
        width: 100%;
    }

    .pYPImg {
        width: 100%;
        max-height: 100%;
    }
    
    .pYPWeb {
        display: none;
    }

    .pYPTitle {
        position: static;
        margin: 9% auto 9%;
        width: 70%;
        font-size: 21px;
        font-weight: 700;
        color: var(--second-color);
    }

    .pYPButton {
        position: static;
        --bttn-height: 51px;
        height: var(--bttn-height);
        width: 80%;
        border-radius: calc(var(--bttn-height) / 2);
        font-size: 18px;
        color: var(--fourth-color);
        background-color: var(--first-color);
    }
}
