.swiper-property-card {
    width: 285px !important;
    display: flex !important;
    justify-content: center !important;
}

.property-card-img {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.property-card-img img {
    width: 285px;
    height: 202px;
    margin-left: 45px;
    margin-right: 15px;
    border-radius: 5px;
    margin-top: 2px;
}

.pc_btn_favorito {
    display: flex;
    position: absolute;
    width: 95%;
    margin: 0 auto;
    top: 12px;
    justify-content: flex-end;
}

.pc_btn_favorito a {
    z-index: 100;
}

.property-card-container .swiper-button-next::after {
    background-color: rgba(255, 255, 255, 0.7);
    font-size: 20px !important;
    text-align: center;
    padding: 8px 12px;
    border-radius: 100%;
    color: black;
    font-weight: bold;
}

.property-card-container .swiper-button-prev::after {
    background-color: rgba(255, 255, 255, 0.7);
    font-size: 20px !important;
    text-align: center;
    padding: 8px 12px;
    border-radius: 100%;
    color: black;
    font-weight: bold;
}

.property-card-container {
    position: relative;
    width: 298px;
    height: 411px;
    margin: 10px auto 10px; 
    padding: 5px 5px 5px;
    box-shadow: 0px 3px 5px rgb(168, 166, 166);;
    border-radius: 5px;
}

.informacion_inmueble {
    width: 100%;
    padding: 8px;
}

.section_info_general {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.precio_tipo h1 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0px;
}

.precio_tipo p {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0px;
    color: #707070;
}

.administracion_info {
    font-size: 14px;
    text-align: end;
    color: #707070;
    margin-bottom: 0px;
}

.ubicacion_inmueble {
    font-size: 16px;
    font-weight: 300;
}

.ciudad_inmueble {
    font-size: 14px;
    font-weight: 300;
    color: #707070;
    line-height: 19.1px;
}

.section_info_distribucion {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin: 20px 0 0;
}

.section_info_distribucion img {
    margin-right: 3px;
}

.bed_info_container {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.shower_info_container {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.area_info_container {
    width: 105px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.parking_info_container {
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.section_detalle {
    display: flex;
    margin: 16px 0px;
    justify-content: flex-end;
}

.detalle_button {
    height: 22.87px;
    width: 90.86px;
    font-size: 11.5px !important;
    font-weight: 400;
    padding: 3.5px 17px;
    border-radius: 20px;
    background-color: var(--first-color);
    color: var(--fourth-color) !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.detalle_button::after {
    width: 0% !important;
    height: 0px !important;
}

.favoriteIcon{
    position: absolute;
    margin: 10px 5px 0 0;
    top: 0%;
    right: 0;
    z-index: 100;
    height: 10% !important;
    cursor: pointer;
}