.Mui-selected {
  color: var(--first-color) !important;
}

.MuiBox-root .css-qh29qi {
  margin-top: 5px;
}

.css-ahj2mt-MuiTypography-root {
  font-size: 14px !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 0px !important;
}

.noAccordionText {
  padding: 15px 0 !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  top: 10px;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  top: 10px;
}

.css-9l3uo3 {
  font-size: 14px !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters {
  border-radius: 3px !important;
  border: 1px solid #B7B7B7 !important;
  margin: 10px 0;
  padding: 0 10px 0 10px;
  box-shadow: none !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}

.accordeon-description {
  padding: 0 0 17px 0 !important;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 0 !important;
}

.css-1q2h7u5 {
  padding: 0 !important;
  min-width: 0 !important;
  font-size: 18px !important;
  top: 10px !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-family: 'Nunito', sans-serif !important;
  padding: 0px !important;
  min-width: 0px !important;
  line-height: 24.55px;
  font-weight: 300;
}

.css-heg063-MuiTabs-flexContainer {
  justify-content: space-between !important;
  width: 85%;
  margin: 0 0 0 15px;
}

.css-19kzrtu {
  margin-top: 40px;
  padding: 0 15px 0 15px !important;
}

.css-k008qs {
  display: flex;
  width: 85%;
  justify-content: space-between;
  margin: 0 0 0 15px;
}

.accordionTitle {
  font-size: 16px;
  margin: 2px 0 0 0;
  color: var(--sixth-color);
  font-weight: 700 !important;
}

.css-o4b71y-MuiAccordionSummary-content {
  display: flex;
  flex-direction: column !important;
}

.css-1iji0d4 {
  padding: 0px !important;
}

.css-u7qq7e {
  padding: 0px !important;
}

@media (min-width: 576px) {
  .css-heg063-MuiTabs-flexContainer {
    width: 60%;
    margin: 0 0 0 25px;
  }

  .css-k008qs {
    width: 60%;
    margin: 0 0 0 25px;
  }

  .css-ahj2mt-MuiTypography-root {
    font-size: 18px !important;
  }
  .accordionTitle {
    font-size: 18px !important;
  }
  .css-19kzrtu {
    padding: 0 15px 0 25px !important;
  }
  .css-9l3uo3 {
    font-size: 18px !important;
  }
}

@media (min-width: 768px) {
  .css-heg063-MuiTabs-flexContainer {
    width: 50%;
  }
  .css-k008qs {
    width: 50%;
  }
  .MuiBox-root .css-qh29qi {
    margin-top: 20px;
  }
}

@media (min-width: 900px) {
  .MuiBox-root .css-qh29qi {
    margin-top: 30px;
    margin-left: 40px;
  }
  .css-heg063-MuiTabs-flexContainer {
    width: 45%;
    margin: 0 0 0 15px;
  }
  .css-k008qs {
    width: 40%;
    margin: 0 0 0 15px;
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-size: 21px !important;
  }
  .css-ahj2mt-MuiTypography-root {
    font-size: 21px !important;
  }
  .accordionTitle {
    font-size: 21px !important;
  }
  .css-19kzrtu {
    padding: 0 54px 0 54px !important;
  }
  .css-1q2h7u5 {
    font-size: 21px !important;
  }
  .css-9l3uo3 {
    font-size: 21px !important;
  }
}

@media (min-width: 1200px) {
  .css-heg063-MuiTabs-flexContainer {
    width: 35%;
  }
  .css-k008qs {
    width: 30%;
  }
}

@media (min-width: 1500px) {
  .css-heg063-MuiTabs-flexContainer {
    width: 30%;
  }
  .css-k008qs {
    width: 30%;
  }
}

@media (min-width: 2000px) {
  .css-heg063-MuiTabs-flexContainer {
    width: 25%;
  }
}