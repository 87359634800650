.beOurPartnerContainer {
  width: 97%;
  margin-top: 40px;
  margin-inline: auto;
}

.beOurPartnerTextCont {
  padding: 0% 5% 10% 5%;
}

.beOurPartnerContTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10%;
}

.beOurPartnerContTitle p {
  width: 90%;
  text-align: center;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;
  margin-bottom: 0px;
  color: var(--sixth-color);
}

.beOurPartnerSubCont1, .beOurPartnerSubCont2, .beOurPartnerSubCont3, .beOurPartnerSubCont4 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.beOurPartnerTextImg {
  display: none;
}

.beOurPartnerImg {
  display: flex;
}

.beOurPartnerImg img {
  width: 100%;
  text-align: center;
}

.beOurPartnerTitleCont {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 24.55px;
  color: var(--sixth-color);
  margin-top: 24.49px;
}

.beOurPartnerText {
  text-align: start;
  font-size: 14px;
  font-weight: 300;
  line-height: 19.1px;
  color: var(--sixth-color);
  margin-top: 16px;
}

.beOurPartnerButtonCont{
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
}

.beOurPartnerButton {
  background-color: var(--first-color);
  width: 341px;
  height: 37px;
  border-radius: 25px;
  font-weight: 500;
  font-size: 21px;
  line-height: 28px;
  margin: 5px 0 10px 0;
  color: var(--fourth-color);
  border: none;
}

@media (min-width: 576px) {
  .beOurPartnerContTitle p {
    font-size: 24px;
    line-height: 0px;
  }

  .beOurPartnerTitleCont {
    font-size: 24px;
    line-height: 30px;
    margin-top: 40px;
  }

  .beOurPartnerTextCont p {
    text-align: center;
  }

  .beOurPartnerText {
    font-size: 18px;
    margin: 30px 0 70px 0;
    line-height: 24.55px;
  }

  .beOurPartnerButton {
    width: 390px;
    height: 45px;
    font-size: 24px;
    line-height: 32.74px;
  }
}

@media (min-width: 992px) {
  .beOurPartnerContTitle {
    margin-bottom: 1%;
  }

  .beOurPartnerContTitle p {
    font-size: 32px;
    line-height: 43.65px;
    margin-top: 10px;
    text-align: left;
  }

  .beOurPartnerTitleCont {
    font-size: 32px;
    line-height: 44px;
    margin-top: 40px;
  }

  .beOurPartnerText {
    font-size: 18px;
    margin: 30px 0 70px 0;
    line-height: 24.55px;
  }
}

@media (min-width: 1200px) {
  .beOurPartnerContainer {
    background-repeat: no-repeat;
    background-position: right 0px bottom -320px;
  }

  .beOurPartnerTextCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 625px;
    flex-basis: 50%;
    text-align: start;
    padding: 0%;
  }

  .beOurPartnerTextImg {
    display: inline;
    margin-bottom: 30px;
  }

  .beOurPartnerTitleCont {
    font-size: 32px;
    line-height: 44px;
    margin-top: 0;
  }

  .beOurPartnerText {
    font-size: 18px;
    line-height: 25px;
    margin: 0 0 0 0;
  }

  .beOurPartnerImgCont {
    position: relative;
    flex-basis: 50%;
  }
  
  .beOurPartnerSubCont1 {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    padding: 40px 2.5% 0.5% 2.5%;
  }

  .beOurPartnerSubCont2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.5% 2.5% 0.5% 2.5%;
  }

  .beOurPartnerSubCont3 {
    margin-top: 0px;
    padding: 0 40px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    padding: 0.5% 2.5% 0.5% 2.5%;
  }
  
  .beOurPartnerSubCont4 {
    margin-top: 0px;
    padding: 0 40px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 0.5% 2.5% 3% 2.5%;
  }

  .beOurPartnerSubCont1 .beOurPartnerImgCont, .beOurPartnerSubCont3 .beOurPartnerImgCont {
    padding-left: 30px;
    align-items: flex-end;
  }

  .beOurPartnerSubCont2 .beOurPartnerImgCont, .beOurPartnerSubCont4 .beOurPartnerImgCont {
    padding-right: 30px;
  }

  .beOurPartnerSubCont1 .beOurPartnerTextCont, .beOurPartnerSubCont3 .beOurPartnerTextCont {
    padding-right: 70px;
    margin: 0 0 0 auto;
  }

  .beOurPartnerSubCont2 .beOurPartnerTextCont, .beOurPartnerSubCont4 .beOurPartnerTextCont {
    position: relative;
    padding-left: 80px;
    margin: 0 auto 0 0;
  }

  .beOurPartnerSubCont2 .beOurPartnerImg ,.beOurPartnerSubCont4 .beOurPartnerImg {
    justify-content: right;
  }

  .beOurPartnerImg img{
    position: relative;
    max-width: 625px;
    z-index: 2;
  }

  .beOurPartnerButtonCont {
    display: flex;
    justify-content: center;
    margin-bottom: 70px;
  }
  
  .beOurPartnerButton {
    --buttonHeight: 50px;
    width: 341px;
    height: var(--buttonHeight);
    border-radius: 25px;
    background-color: var(--first-color);
    font-weight: 500;
    font-size: 24px;
    margin: 35px 0 10px 0;
    color: var(--fourth-color);
    border: none;
  }
}

.beOurPartnerDialog{
  width: 900px;
}

@media (max-width: 900px) {
  .beOurPartnerDialog{
    width: 100%;
  } 
}

@media (min-width: 1600px) {
  .beOurPartnerContTitle {
    margin-left: 10%;
  }  
}

.beOurPartnerDialog .closebtn{
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 2;
  border-radius: 30px;
  padding: 10px;
  cursor: pointer;
}

.beOurPartnerDialog .closebtn:hover{
  background-color: black;
  color:white !important;
}

.beOurPartnerDialogIframe{
  width: 100%;
  height: 90vh;
}
