#button-WhatsApp {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 100px;
    right: 20px;
    background-color: #26c281;
    margin: 0px !important;
}

#button-WhatsApp .MuiSvgIcon-root {
  margin-inline: auto !important;
}

