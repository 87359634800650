.PropertyDetail .pd_h1 {
    color: black;
    font-size: 32px;
}

.PropertyDetail .valor_canon {
    text-align: right;
    font-size: 48px;
    line-height: 48px;
    font-weight: 400;
    margin: 0px;
    padding: 0px;
}

.PropertyDetail .valor_admin {
    text-align: right;
    font-size: 18px;
    font-weight: 400;
    margin: 0px;
    padding: 0px;
    color: #7c7b7b;
}

@media (max-width: 899px) {
    .PropertyDetail .valor_canon {
        text-align: left;
        font-size: 32px;
        line-height: 38px;
        font-weight: 400;
        margin-top: 10px;
    }

    .PropertyDetail .valor_admin {
        text-align: left;
    }
}

.PropertyDetail .pd_items img {
    height: 40px;
    margin-right: 5px;
}

.PropertyDetail .pd_items span {
    display: inline-block;
    padding-right: 3%;
    font-size: 20px;
    margin-top: 5px;
}

@media (max-width: 899px) {
    .PropertyDetail .pd_items {
        margin-top: 20px;
    }

    .PropertyDetail .pd_items img {
        height: 22px;
        margin-right: 2px;
    }

    .PropertyDetail .pd_items span {
        font-size: 14px;
        padding-right: 12px;
        margin-top: 10px;
    }
}

.PropertyDetail .swiper-slide {
    height: 30vw;
    background-color: #f1f1f1;
}

.PropertyDetail .pd_photo {
    width: 100%;
    height: 100%;
    background-size: cover;
}

@media (max-width: 899px) {
    .PropertyDetail .swiper-slide {
        height: 60vw;
    }
}

.PropertyDetail .pd_photos {
    margin-top: 20px;
    overflow: hidden;
    border-radius: 8px;
}

.PropertyDetail .swiper-button-next:after,
.PropertyDetail .swiper-button-prev:after {
    background-color: rgba(255, 255, 255, 0.7);
    font-size: 20px !important;
    text-align: center;
    padding: 8px 12px;
    border-radius: 100%;
    color: black;
    font-weight: bold;
}

.PropertyDetail .pd_codigoimmueble {
    text-align: right;
    padding: 5px 0px;
    font-size: 16px;
    color: black;
}

@media (max-width: 899px) {
    .PropertyDetail .pd_codigoimmueble {
        font-size: 14px;
    }
}

.PropertyDetail .pd_caracteristicas{
    margin-top: 20px;
}

.PropertyDetail .pd_area_caracteristicas{
    padding: 20px;
    margin-bottom: 30px;
}

.PropertyDetail .pd_caracteristica{
    padding: 5px 0px;
}

.PropertyDetail .pd_caracteristica img{
    width: 30px;
    margin-right: 20px;
}

.PropertyDetail .pd_caracteristica{
    color: #7C7B7B;
}

.pd_modal{
    padding: 150px 30px 20px 30px;
    background-image: url(../../../assets/static/images/slogo.svg);
    background-position: center 34px;
    background-repeat: no-repeat;
    background-size: auto 82px;
    text-align: center;
    width: 410px;
    max-width: 100%;
    position: relative;
    border-radius: 20px !important;
}

.pd_modal h1{
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 30px;
    padding: 0px;
}

.pd_modal p{
    font-size: 18px;
}

.pd_modal .btn_inicio{
    background-color: #FF4800;
    border: 1px solid #FF4800;
    border-radius: 40px;
    padding: 6px 30px;
    color: white;
    font-size: 21px;
    margin: 10px;
}

.pd_modal .btn_inicio{
    background-color: #FF4800;
    border: 1px solid #FF4800;
    border-radius: 40px;
    padding: 6px 30px;
    color: white;
    font-size: 21px;
    margin: 10px;
}

.pd_modal .btn_cerrar{
    background-image: url(../../../assets/static/icons/icon_close.svg);
    background-repeat: no-repeat;
    background-position: center center;
    width: 32px;
    height: 32px;
    background-size: auto auto;
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
    background-color: transparent;
}

.PropertyDetail .pd_btn_favorito{
    position: relative;
}

.PropertyDetail .pd_btn_favorito a{
    position: absolute;
    top: -30px;
    right: 5px;
    width: 35px;
    height: 35px;
    display: block;
    z-index: 2;
    cursor: pointer;
}

.PropertyDetail .pd_btn_favorito img{
    width: 100%;
    height: 100%;
}

@media (max-width: 899px) {
    .PropertyDetail .pd_btn_favorito a{
        top: 25px;
        width: 30px;
        height: 30px;    }
}