.favoritesContainer {
  width: 97%;
  margin: 57px auto 50px;
}

.favoritesText {
  width: 89%;
  margin-inline: auto;
  margin-bottom: 37px;
}

.favoritesText h1{
  font-size: 32px;
  font-weight: 800;
  color: black;
}

.favoritesText p{
  font-size: 18px;
  font-weight: 400;
  color: #7C7B7B;
}

.favoritesProperties {
  width: 89%;
  margin-inline: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, 298px);
  gap: 30px 10px;
  justify-content: space-between;
  align-items: center;
}

.favoritesEmpty {
  width: 89%;
  margin-inline: auto;
  font-size: 18px;
}

@media (max-width: 992px) {
  .favoritesContainer{
    margin-top: 41px;
  }

  .favoritesText h1{
    font-size: 24px;
    font-weight: 700;
  }
  
  .favoritesText p{
    font-size: 16px;
  }
  
  .favoritesProperties {
    justify-content: space-around;
  }

  .favoritesEmpty {
    font-size: 16px;
  }
}

@media (min-width: 900px) {
  .favoritesEmpty {
    height: 30vh;
  }
  
}