header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionStatic {
  height: 66px;
  justify-content: center;
  background-color: var(--fourth-color) !important;
  width: 100%;
}

.MenuBase .MuiPaper-root{
  left:0px !important;
  width: 100vw !important;
  max-width: 100vw !important;
  height: 527px !important;
  border-radius: 20px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  top: 0px !important;
}

.MenuBase .MuiButtonBase-root{
  padding: 0px !important;
}

.MenuItemHeader {
  background-color: white !important;
  border: 0px;
  display: block !important;
  width: 100vw !important;
}

.MenuItemHeader .btn_close {
  background-color: white;
  border: 0px;
  padding: 10px;
  border-bottom: 1px solid #b6b3b3;
  border-radius: 0px !important;
  width: 100%;
  text-align: right !important;
  font-size: 16px;
  margin-bottom: 20px;
}
.MenuItemHeader .btn_close span {
  margin-right: 10px;
}

.MenuItemHeader a {
  background-color: white;
  display: block;
  padding: 22px 0px;
  text-align: center !important;
  font-size: 18px;
  border-radius: 4px;
}

.MenuItemHeader a:hover{
  background-color: #FF5614;
  color: white !important;
} 

.MenuItemHeader .btn_inicio_sesion{
  background-color: #FF5614;
  color: white !important;
  padding: 25px 0px;
  font-size: 24px;
  margin-top: 20px;
  border-radius: 0px !important;
}

a {
  text-decoration: none !important;
  color: var(--sixth-color) !important;
}

header a:-webkit-any-link {
  text-decoration: none !important;
  color: var(--second-color);
}

#pToHeader {
  font-size: 18px;
  text-decoration: none !important;
  color: var(--first-color);
  cursor: pointer;
}

.button_container_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button_header {
  width: 142px !important;
  height: 36px !important;
  margin-top: 40px !important;
  background-color: white;
  height: 40px;
  width: 264px;
  align-self: center;
  border-radius: 25px;
  border: 1px solid var(--second-color);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.button_header:hover {
  cursor: pointer;
  background-color: var(--third-color);
}

.rendara-logo-header {
  width: 141px;
  height: 42px;
}

.button_container_header a {
  margin-top: 05px !important;
  align-self: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
}

header .button-no-hover:-webkit-any-link:after {
  height: 0px;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  height: 410px;
  padding: 0 !important;
}

@media (min-width: 1200px) {
  .css-1g2j2ht {
    display: flex !important;
    justify-content: space-between !important;
    align-items: flex-end;
    width: 70%;
  }
  .rendara-logo-header {
    width: auto;
    height: auto;
    max-width: 208.46px;
    min-height: 61.69px;
  }

  a:-webkit-any-link {
    font-size: 17px;
  }

  #pToHeader {
    font-size: 15px;
  }
  header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionStatic {
    padding: 0px 1.5%;
  }
  header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionStatic {
    position: sticky !important;
    min-height: 99.88px;
    justify-content: center;
    z-index: 100;
  }
  .css-19wv5ok {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
  .button_container_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
  }
  .button_header {
    margin-top: 0px !important;
    background-color: transparent;
    border: none;
    height: 36px;
    width: 142px;
    align-self: center;
    border-radius: 25px;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    color: var(--second-color);
  }
  .button_header:hover {
    cursor: pointer;
    color: var(--second-color);
  }
  header a:-webkit-any-link {
    display: inline-block;
    position: relative;
    color: var(--second-color);
    font-size: 16px;
    font-weight: 300;
    line-height: 21.82px;
    text-decoration: none !important;
    text-align: center;
  }

  #pToHeader {
    font-size: 14px;
    font-weight: 400;
    line-height: 22.5px;
    display: inline-block;
    position: relative;
    text-decoration: none !important;
    color: var(--second-color);
  }

  header a:-webkit-any-link:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--third-color);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  header a:-webkit-any-link:hover {
    color: black;
  }

  #pToHeader::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--third-color);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  header a:-webkit-any-link:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  #pToHeader:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .button_container_header a {
    margin-top: 05px !important;
    align-self: center;
    font-weight: 700 !important;
    font-size: 21px !important;
  }
}

@media (min-width: 1400px) {
  .css-1g2j2ht {
    width: 750px;
  }
}
