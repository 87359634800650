.beOurPartnerBanner {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.beOurPartnerBanner div:first-of-type {
  width: 100%;
}

.beOurPartnerBannerImgWeb {
  width: 100%;
  max-height: 100%;
}

.beOurPartnerBannerImgMobile, .beOurPartnerDescMobile {
  display: none;
}

.beOurPartnerTitle {
  position: absolute;
  display: block;
  width: 100%;
  top: 35%;
  font-size: 6vh;
  font-weight: 600;
  text-align: center;
  color: var(--fourth-color);
}

.beOurPartnerDescWeb {
  width: 90%;
  position: absolute;
  top: 60%;
  margin: 0 5% 0 5%;
  font-size: 3.5vh;
  font-weight: 600;
  text-align: center;
  color: var(--fourth-color);
}

@media (max-width: 992px) {
  .beOurPartnerBannerImgWeb, .beOurPartnerDescWeb {
    display: none;
  }

  .beOurPartnerBannerImgMobile {
    display: inline;
    width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  
  .beOurPartnerTitle {
    top: 29%;
    font-size: 7.5vw;
    text-align: center;
  }

  .beOurPartnerDescMobile {
    display: inline;
    width: 87%;
    margin: 30px 0 30px 0;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
  }
}
