.whyWithUsContainer {
  width: 97%;
  margin-top: 40px;
  margin-inline: auto;
}

.whyWithUsTextCont {
  padding: 0% 5% 10% 5%;
}

.whyWithUsTitleCont {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}

.whyWithUsTitleCont p {
  width: 90%;
  text-align: center;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;
  margin-bottom: 0px;
  color: var(--sixth-color);
}

.whyWithUsSubCont1, .whyWithUsSubCont2, .whyWithUsSubCont3, .whyWithUsSubCont4 {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.whyWithUsImg {
  display: flex;
}

.whyWithUsImg img {
  width: 100%;
  text-align: center;
}

.whyWithUsSubContBg1 {
  display: none;
}

.whyWithUsSubContBg2 {
  display: none;
}

.whyWithUsSubContBg3 {
  display: none;
}

.whyWithUsSubContBg4 {
  display: none;
}

.whyWithUsSubContTitle {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 24.55px;
  color: var(--sixth-color);
  margin-top: 24.49px;
}

.whyWithUsSubContText {
  text-align: start;
  font-size: 14px;
  font-weight: 300;
  line-height: 19.1px;
  color: var(--sixth-color);
  margin-top: 16px;
}

@media (min-width: 576px) {
  .whyWithUsTitleCont p {
    font-size: 24px;
    line-height: 0px;
    margin-bottom: 10px;
  }

  .whyWithUsSubContTitle {
    font-size: 24px;
    line-height: 30px;
    margin-top: 40px;
  }

  .whyWithUsSubContText {
    font-size: 18px;
    margin: 30px 0 70px 0;
    line-height: 24.55px;
  }
}

@media (min-width: 992px) {
  .whyWithUsTitleCont {
    margin-bottom: 1%;
  }

  .whyWithUsTitleCont p {
    width: 100%;
    font-size: 32px;
    line-height: 43.65px;
    margin-bottom: 30px;
  }

  .whyWithUsSubContTitle {
    font-size: 32px;
    line-height: 44px;
    margin-top: 40px;
  }

  .whyWithUsSubContText {
    font-size: 18px;
    margin: 30px 0 70px 0;
    line-height: 24.55px;
  }
}

@media (min-width: 1200px) {
  .whyWithUsTitleCont {
    margin-bottom: 0px;
    margin-inline: auto;
    width: 85%;
  }

  .whyWithUsContainer {
    background-repeat: no-repeat;
    background-position: right 0px bottom -320px;
  }

  .whyWithUsTitleStart {
    text-align: end;
  }

  .whyWithUsTextStart {
    text-align: end;
  }

  .whyWithUsTitleEnd {
    text-align: start;
  }

  .whyWithUsTextEnd {
    text-align: start;
  }

  .whyWithUsTitleCont p {
    text-align: left;
    font-size: 32px;
    margin-bottom: 55px;
  }

  .whyWithUsTextCont {
    max-width: 625px;
    flex-basis: 50%;
    text-align: start;
    padding: 0%;
  }

  .container_text_why_post p {
    font-size: 21px;
    line-height: 35px;
  }

  .whyWithUsSubContTitle {
    font-size: 32px;
    line-height: 44px;
    margin-top: 0;
  }

  .whyWithUsSubContText {
    font-size: 18px;
    line-height: 25px;
    margin: 35px 0 0 0;
  }

  .whyWithUsImgCont {
    position: relative;
    flex-basis: 50%;
  }
  
  .whyWithUsSubCont1 {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    padding: 3% 2.5% 3% 2.5%;
  }

  .whyWithUsSubCont2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 3% 2.5% 3% 2.5%;
  }

  .whyWithUsSubCont3 {
    margin-top: 0px;
    padding: 0 40px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    padding: 3% 2.5% 3% 2.5%;
  }
  
  .whyWithUsSubCont4 {
    margin-top: 0px;
    padding: 0 40px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 3% 2.5% 3% 2.5%;
  }

  .whyWithUsSubCont1 .whyWithUsImgCont, .whyWithUsSubCont3 .whyWithUsImgCont {
    padding-left: 30px;
    align-items: flex-end;
  }

  .whyWithUsSubCont2 .whyWithUsImgCont, .whyWithUsSubCont4 .whyWithUsImgCont {
    padding-right: 30px;
  }

  .whyWithUsSubCont1 .whyWithUsTextCont, .whyWithUsSubCont3 .whyWithUsTextCont {
    padding-right: 70px;
    margin: 0 0 0 auto;
  }

  .whyWithUsSubCont2 .whyWithUsTextCont, .whyWithUsSubCont4 .whyWithUsTextCont {
    position: relative;
    padding-left: 80px;
    margin: 0 auto 0 0;
  }

  .whyWithUsSubCont2 .whyWithUsImg ,.whyWithUsSubCont4 .whyWithUsImg {
    justify-content: right;
  }

  .whyWithUsImg img{
    position: relative;
    max-width: 625px;
    z-index: 2;
  }

  .whyWithUsSubContBg1 {
    display: inline;
    position: absolute;
    bottom: 0.5%;
    left: -110px;
    z-index: 1;
  }

  .whyWithUsSubContBg2 {
    display: inline;
    position: absolute;
    bottom: -55%;
    left: -115px;
    z-index: 1;
  }

  .whyWithUsSubContBg3 {
    display: inline;
    position: absolute;
    bottom: 0.5%;
    left: -95px;
    z-index: 1;
  }

  .whyWithUsSubContBg4 {
    display: inline;
    position: absolute;
    bottom: -55%;
    left: -157px;
    z-index: 1;
  }

  .button_why_post {
    height: 50px;
    width: 280px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }

  .container_text_why_post p {
    text-align: start;
  }
}
