.SearchFilter{
    padding: 16px 20px;
    width: 750px;
    height: auto;
    border-radius: 20px;
}

.SearchFilter .filtersTitle {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    width: 98%;
    margin: 0;
}

.numberFilter {
    border: 1px solid #898787 !important;
    border-radius: 100px !important;
    padding: 2px 0px 2px 0px !important;
    height: 26px !important;
    width: 26px !important;
    margin-right: 13px !important;
    font-size: 14px !important;
    justify-content: center !important;
    align-items: center !important;
    margin-bottom: 7px !important;
}

.textFilter {
    border: 1px solid #898787 !important;
    border-radius: 9px !important;
    padding: 0 7px !important;
    height: 26px !important;
    width: auto !important;
    font-size: 14px !important;
    margin-right: 5px !important;
    margin-bottom: 7px !important;
}

.SearchFilter .Mui-selected {
    background-color: #FF4800 !important;
    color: white !important;
    border: 1px solid #FF4800 !important;
}

.filtersText {
    width: 205px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    color: #898787;
}

.toggleContainer {
    width: 426px;
}

.filterButtonGroup {
    margin-top: 10px;
    display: block !important;
    align-items: center;
    width: 450px
}

.filtersInputs {
    display: flex;
}

.filtersInputs input {
    border: solid 1px #898787;
    width: 78px;
    height: 26px;
    border-radius: 10px;
    margin-right: 5px;
    outline: none;
    text-decoration-line: none;
    padding: 1px 7px 0 7px;
}

.filtersInputs .MuiOutlinedInput-notchedOutline {
    border: none !important;
    padding: 0;
}

.filtersInputsContainer1 {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    align-items: center;
}

.filtersInputsContainer2 {
    display: flex;
    margin-top: 7px;
    margin-bottom: 7px;
    align-items: center;
}

.css-1tcs2i1-MuiInputBase-root-MuiInput-root:before {
    border-bottom: none !important;
}

.css-1tcs2i1-MuiInputBase-root-MuiInput-root:after {
    border-bottom: none !important;
}

.filterInputsLabel {
    margin: 0;
    text-align: center;
    width: 93px;
    color: #898787;
}

.filtersButtonContainer {
    display: flex;
    justify-content: center;
}

.filterCancelButton {
    align-items: center !important;
    background-color: var(--fourth-color) !important;
    display: flex !important;
    font-weight: 400 !important;
    height: 30px !important;
    justify-content: center !important;
    width: 100px !important;
    color: var(--sixth-color) !important;
    font-size: 11.5px !important;
    border-radius: 6px !important;
    padding: 3.5px 17px !important;
    margin: 0 7px !important;
}

.filterEraseButton {
    align-items: center !important;
    background-color: #C4C4C4 !important;
    display: flex !important;
    font-weight: 400 !important;
    height: 30px !important;
    justify-content: center !important;
    width: 100px !important;
    color: var(--sixth-color) !important;
    font-size: 11.5px !important;
    border-radius: 6px !important;
    padding: 3.5px 17px !important;
    margin: 0 7px !important;
}

.filterAgreeButton {
    align-items: center !important;
    background-color: var(--first-color) !important;
    display: flex !important;
    font-weight: 400 !important;
    height: 30px !important;
    justify-content: center !important;
    width: 100px !important;
    color: var(--fourth-color) !important;
    font-size: 11.5px !important;
    border-radius: 6px !important;
    padding: 3.5px 17px !important;
    margin: 0 7px !important;
}

.filtersContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {
    padding: 0;
}

.filterTopContainer {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filterTopContainer button {
    width: 2%;
    all: unset;
    font-size: 25px;
    display: flex;
}

.helperTextError {
    padding: 0 20px;
    display: flex;
    align-items: flex-end;
    text-align: center !important;
}

.helperTextErrorMobile {
    display: none;
}

@media (max-width: 900px) {
    .SearchFilter{
        padding: 16px 10px;
        width: 320px;
        height: auto;
        border-radius: 20px;
    }
    .filterButtonGroup {
        margin-top: 10px;
        display: block !important;
        align-items: center;
        width: 300px
    }
    .filtersContainer {
        display: grid;
        grid-template-columns: repeat(1, 1fr) !important;
    }
    .helperTextErrorMobile {
        padding: 0 10px 0 0;
        display: flex;
        align-items: flex-end;
        text-align: start !important;
    }
    .helperTextError {
        display: none;
    }
    .SearchFilter .filtersTitle {
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        width: 85%;
        margin: 0;
    }
    .filterTopContainer {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .filterTopContainer button {
        width: 15%;
        all: unset;
        font-size: 25px;
        display: flex;
    }
}