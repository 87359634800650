.passionate_title {
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    line-height: 45px;
    color: var(--sixth-color);
    padding: 80px 20px 0 20px;
    margin: 0;
}

.passionate_text {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: var(--sixth-color);
    padding: 0 20px 35px 20px;
}

.text_banner_mobile_container {
    max-width: 550px;
    margin: 0 auto;
}

.container_passionate {
    max-width: 550px;
    margin: 0 auto;
}

@media (min-width: 900px) {
    .container_passionate {
        padding: 90px 0;
        display: flex;
        max-width: 90%;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        height: 300px;
    }
    .passionate_title {
        padding: 0px;
        font-size: 45px;
    }
    .passionate_text {
        width: 80%;
        padding: 0px;
        font-size: 24px;
        line-height: 30px;
    }
}
.experience_container {
  margin: 20px auto 0 auto;
  max-width: 550px;
  display: flex;
  flex-direction: column;
}

.experience_since_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.experience_title_container {
  width: 100%;
}

.experience_title {
  max-width: 550px;
  text-align: center;
  font-size: 21px;
  line-height: 28.64px;
  font-weight: 700;
  color: var(--second-color);
  padding: 5px 20px 0px 20px;
}

.experience_years_eighty {
  text-align: center;
  font-size: 60px;
  font-weight: 900;
  margin: 20px 0 0 0;
  color: var(--first-color);
}

.experience_years_fourteen {
  text-align: center;
  font-size: 60px;
  font-weight: 900;
  margin: 20px 0 0 0;
  color: #14AAFF;
}

.anios_eighty {
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  color: var(--first-color);
  bottom: 25px !important;
  right: 26px;
  margin: 0px;
  position: relative;
}

.anios_fourteen {
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  color: #14AAFF;
  bottom: 25px !important;
  right: 26px;
  margin: 0px;
  position: relative;
}

.experience_description_text_eighty {
  width: 80%;
  margin: 0 auto 15px auto;
  padding: 0 0px;
  font-size: 16px;
  line-height: 21.82px;
  font-weight: 500;
  text-align: left;
  color: var(--second-color);
}

.experience_description_text_fourteen {
  width: 80%;
  margin: 0 auto 15px auto;
  padding: 0px;
  font-size: 16px;
  line-height: 21.82px;
  font-weight: 500;
  text-align: left;
  color: var(--second-color);
}

.experience_content {
  margin-bottom: 35px;
}

.button_container_passionate {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 45px;
}

.button_container_passionate .filled_button {
  background-color: var(--first-color);
  color: var(--fourth-color);
  width: 286px;
  height: 51px;
  align-self: center;
  border-radius: 25px;
  border: 1px solid var(--first-color);
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 16px;
}

.button_container_passionate .border_button {
  background-color: var(--fourth-color);
  color: var(--first-color);
  width: 286px;
  height: 51px;
  align-self: center;
  border-radius: 25px;
  border: 1px solid var(--first-color);
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 16px;
}

.button_container_passionate button:hover {
  cursor: pointer;
}

@media (min-width: 900px) {
  .experience_container {
    max-width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .experience_content {
    display: flex;
    align-items: stretch;
    width: 90%;
    flex-direction: column;
  }
  .experience_content_group_eighty {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
  }
  .experience_content_group_fourteen {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  .experience_title {
    max-width: 1300px;
    font-size: 26px;
    text-align: left;
    margin-bottom: 40px;
  }
  .experience_title_container {
    width: 100%;
    margin: 0 auto;
  }
  .experience_description_text_eighty {
    margin-top: 22px;
    width: 100%;
    font-size: 24px;
    line-height: 32.74px;
    margin: 20px auto 0 50px;
  }
  .experience_description_text_fourteen {
    margin-top: 22px;
    width: 100%;
    font-size: 24px;
    line-height: 32.74px;
    margin: 20px 50px 0 auto;
  }
  .experience_years_eighty {
    font-size: 100px;
  }
  .experience_years_fourteen {
   font-size: 100px;
  }
  .anios_eighty {
    font-size: 32px;
    bottom: 40px !important;
    right: 50px;
    margin: 0px;
    position: relative;
  }

  .anios_fourteen {
    font-size: 32px;
    bottom: 40px !important;
    right: 50px;
    margin: 0px;
    position: relative;
  }
  .button_container_passionate {
    flex-direction: row;
    width: 80%;
    margin-top: 50px;
    margin-bottom: 100px;
    justify-content: space-around;
  }
  .button_container_passionate .filled_button {
    width: 290px;
    height: 53px;
    font-size: 18px;
  }

  .button_container_passionate .border_button {
    width: 290px;
    height: 53px;
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .experience_title {
    max-width: 1300px;
    font-size: 32px;
  }
  .experience_text {
    font-size: 18px;
    line-height: 35px;
  }
  .button_container_passionate .filled_button {
    width: 347px;
    height: 58px;
    font-size: 24px;
    border-radius: 50px;
  }

  .button_container_passionate .border_button {
    width: 347px;
    height: 58px;
    font-size: 24px;
    border-radius: 50px;
  }
}

@media (min-width: 1400px) {
  .button_container_passionate .filled_button {
    width: 447px;
    height: 65px;
    font-size: 28px;
  }

  .button_container_passionate .border_button {
    width: 447px;
    height: 65px;
    font-size: 28px;
  }
}