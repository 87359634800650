.BackPage {
  margin: 0px 0px 10px;
  position: relative;
}

.BackPage .btn p {
  display: inline-block;
  color: #7c7b7b;
  font-size: 16px;
  position: relative;
  top: 2px;
  text-indent: 2px;
}

.BackPage .btn p:hover {
  text-decoration: underline;
}

.BackPage .btn svg {
  color: black;
}
