.homeCardsContainer {
  width: 100%;
  margin-bottom: 50px;
  border-bottom: 1px dotted #ffa17d;
}

.homeCardsTitle {
  margin-top: 6vw;
  margin-bottom: 45px;
  font-size: 18px;
  font-weight: 800;
  text-align: center;
}

.homeCardsBgRegion {
  padding-bottom: 60px;
  margin-bottom: 20px;
  background-image: url("../../../assets/static/icons/library.svg"),
  url("../../../assets/static/icons/couch.svg"),
  url("../../../assets/static/icons/stove.svg");
  background-repeat: no-repeat;
  background-position: left bottom, center bottom, right bottom;
}

.homeCardsCards {
  width: 100%;
  margin: 10px auto 40px;
  display: grid;
  grid-template-columns: 1fr;
}

.homeCardsButton {
  width: 90%;
  margin: 0 auto;
}
.homeCardsCard {
  width: 75%;
  min-height: fit-content;
  padding: 10px;
  margin: 21px auto 30px;
  border-radius: 20px;
  box-shadow: 0px 3px 3px rgb(168, 166, 166);
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: var(--fourth-color);
}

.homeCardsCard img {
  display: block;
  width: 40%;
  margin-bottom: 30px;
}

.homeCardsCard h1 {
  height: 10%;
  margin-bottom: 10px;
  font-size: 24px;
  text-align: center;
}

.homeCardsCard p {
  height: fit-content;
  padding: 5px 10px 10px 10px;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 400;
  text-align: start;
  line-height: 22px;
}

.homeCardsButton {
  display: flex;
  justify-content: center;
}

.homeCardsButton button {
  width: 90%;
  height: 51px;
  background-color: var(--first-color);
  border-radius: 40px;
  padding: 15px;
  border: none;
  font-size: 18px;
  color: white;
}

.homeCardsButton a::after {
  width: 0% !important;
  height: 0px !important;
}

@media (min-width: 550px) {
  .homeCardsButton button {
    width: 60%;
    background-color: var(--first-color);
    border-radius: 40px;
    padding: 15px;
    border: none;
    font-size: 25px;
    color: white;
  }
}

@media (min-width: 900px) {
  .homeCardsTitle {
    width: 82.45%;
    max-height: 470px;
    margin-bottom: 75px;
    margin-inline: auto;
    font-size: 24px;
  }

  .homeCardsBgRegion {
    padding-bottom: 80px;
  }

  .homeCardsCards {
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .homeCardsCard {
    justify-content: space-evenly;
  }

  .homeCardsCard img {
    width: 45%;
    height: 45%;
  }

  .homeCardsCard h1 {
    height: 20%;
    font-size: 32px;
    margin-bottom: 0px;
  }

  .homeCardsCard p {
    height: 35%;
  }

  .homeCardsButton {
    height: 53px;
  }

  .homeCardsButton button {
    width: 413px;
    padding: 1px;
    background-color: var(--first-color);
    border-radius: 40px;
    border: none;
    font-size: 24px;
    color: white;
  }
}

@media (min-width: 1200px) {
  .homeCardsTitle {
    margin-top: 6vw;
    font-size: 32px;
    font-weight: 800;
    text-align: start;
  }

  .homeCardsBgRegion {
    padding-inline: 1.5% 1.5%;
    background: no-repeat url("../../../assets/static/images/bg_rent.png");
    background-position: bottom center;
    background-size: cover;
  }

  .homeCardsCards {
    margin-bottom: 162px;
  }

  .homeCardsCard {
    max-width: 270px;
    height: 451px;
  }
}
