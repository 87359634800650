.SearchResult {
    height: 100%;
}

@media (max-width: 899px) {
    .SearchResult {
        height: unset;
    }
}

.SearchResult .sr_buscador {
    padding: 20px;
}

.SearchResult .sr_resultado {
    position: relative;
    overflow: auto;
}

@media (max-width: 899px) {
    .SearchResult .sr_resultado {
        overflow: unset;
    }
}

.SearchResult .sr_resultado_header {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    background-color: white;
    padding: 20px 10px 10px;
    border-bottom: 1px solid #cccccc;
    z-index: 110;
}

.SearchResult .btn_map {
    color: black;
    text-transform: none;
}

.SearchResult .btn_map span {
    font-weight: 400 !important;
    text-indent: 5px;
}

.SearchResult .sr_resultado_header .MuiOutlinedInput-root {
    border-radius: 50px;
}

@media (max-width: 899px) {
    .SearchResult .sr_resultado_header {
        position: relative;
    }
}

.SearchResult .sr_resultado_header span {
    font-weight: bold;
}

.SearchResult .sr_resultado_header select {
    border: none;
    background-color: transparent;
    padding: 0px 10px;
    text-align: left;
}

@media (max-width: 1300px) {
    .SearchResult .sr_resultado_header select {
        text-align: right;
    }
}

.SearchResult .sr_resultado_area {
    position: absolute;
    top: 40px;
    left: 0px;
    width: 100%;
    height: calc(100% - 40px);
    overflow-y: scroll;
    padding: 40px 0px 80px 15px;
}

.SearchResult .sr_resultado_area h2 {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    padding: 40px 10px;
    width: 100%;
}

.SearchResult .propiedad {
    border: 4px solid white;
    padding: 0px 4px;
}

.SearchResult .propiedad.propiedad_seleccionado {
    border: 4px solid #ff5614;
    border-radius: 8px;
}

@media (max-width: 899px) {
    .SearchResult .sr_resultado_area {
        position: relative;
        height: unset;
        overflow-y: unset;
    }
}

.Result .marcador_inmueble {
    display: block;
    width: 70px;
    height: 42px;
    background-color: transparent;
    text-align: center;
    color: #ff5614 !important;
    background-image: url(../../../assets/static/images/marker_inmueble.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
    cursor: pointer;
    font-size: 16px;
    padding: 6px 0px;
}

.Result .marcador_inmueble.propiedad_seleccionado {
    color: white !important;
    background-image: url(../../../assets/static/images/marker_inmueble_over.png);
    font-size: 16px;
    padding: 6px 0px;
}

.Result .btn_ocultar_mapa {
    position: absolute;
    bottom: 20px;
    left: 0px;
    width: 80%;
    left: 50%;
    margin-left: -40%;
    background-color: #ff5614;
    border-radius: 50px;
    z-index: 10;
}

.SearchResult .sr_area_mapa {
    position: relative;
}

.SearchResult .sr_emapa {
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    height: 40px;
    padding-left: 10px;
}

.SearchResult .sr_emapa .MuiTypography-root {
    font-size: 16px !important;
}

.SearchResult .pInput {
    background-color: white;
    border-radius: 30px !important;
}

.SearchResult .pInput .MuiOutlinedInput-root {
    border-radius: 30px !important;
}

.SearchResult .btn_filtro {
    padding: 5px 10px;
    border: 1px solid #c8c8c8;
    border-radius: 50px;
    font-size: 18px;
    background-color: white;
    color: #000000;
    width: 120px;
}

.SearchResult .inputArea {
    padding: 5px;
}

.SearchResult .separador {
    border-left: 1px solid #858383;
    padding-left: 20px;
    margin-left: 10px;
}

.SearchResult .item_filtro {
    display: inline-block;
    padding: 5px 10px;
    border: 1px solid #e7e7e7;
    font-size: 16px;
    margin: 4px;
    border-radius: 20px;
}

@media (max-width: 899px) {
    .SearchResult .item_filtro {
        font-size: 12px;
    }
}

.SearchResult .item_filtro button {
    border: none;
    background-color: transparent;
    margin-left: 10px;
}

.SearchResult .area_filtros {
    margin: 15px 0px 0px 0px;
    padding-top: 10px;
    border-top: 1px solid #858383;
}

@media (max-width: 899px) {
    .SearchResult label.MuiFormLabel-root {
        font-size: 13px;
    }
}
